import "../css/navbar.css";

function Navbar({className}) {
    return (
    <div>
        <nav className={`navbar fixed-top navbar-expand-md navbar-light ease-1s m-3 p-2 ${className}`} id="navbar">
            <div className="container-fluid">
                <a className="navbar-brand bold active" aria-current="page" href="/">Home</a>
                <button className="navbar-toggler collapsed" id="navtoggle" type="button" data-bs-toggle="collapse" data-bs-target="#navcontent" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="toggler-icon top-bar rounded-pill"></span>
                    <span className="toggler-icon middle-bar rounded-pill"></span>
                    <span className="toggler-icon bottom-bar rounded-pill"></span>
                </button>
                <div className="collapse navbar-collapse" id="navcontent">
                    <ul className="navbar-nav me-auto mb-2 mb-md-0">
                        <li className="nav-item"><a className="nav-link" href="/experience/">Experience</a></li>
                        <li className="nav-item"><a className="nav-link" href="/projects/">Projects</a></li>
                        <li className="nav-item"><a className="nav-link" href="/gallery/">Gallery</a></li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
    );
}

export default Navbar;