function Brand({content, link, isCircle = false, index = 0}) {
    const x = Math.sin(index * Math.PI / 6) * 330 - 50;
    const y = Math.cos(index * Math.PI / 6) * -330 - 50;

    const styles = {
        placement: {
            position: "absolute",
            margin: "auto",
            top: "50%",
            left: "50%",
            transform: `translate(${x}%,${y}%)`
        }
    };

    return (
    <div>
        {isCircle ? (
            <div style={styles.placement}>
                <a href={link} target="_blank" rel="noreferrer">{content}</a>
            </div>
        ) : (
            <a href={link} target="_blank" rel="noreferrer">{content}</a>
        )}
    </div>
    );
};

export default Brand;