/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import "../css/about.css";
import MediaQuery from "react-responsive";

function About() {
    const [rotate, setRotate] = useState(1);

    function rotateSquare() {
        let angle = rotate * 90;

        const facts = document.getElementsByClassName("fact");
        facts[(rotate-1)%4].classList.remove("fw-bold");
        facts[rotate%4].classList.add("fw-bold");

        setRotate(rotate + 1);

        document.getElementById("about-square").style.transform = "rotate(" + angle + "deg)";
    }
    return (
    <section id="about" className="container-fluid">
        <div className="row fill-height align-items-center">
            <div className="col-1"></div>
            <div className="col-10 text-center reveal">
                <h1 className="lora display-2 marginbtm">About</h1>
                <MediaQuery maxWidth={"992px"}>
                    <ul id="about-list" className="h2 marginbtm">
                        <li>Developer and graphic designer born in Calgary, Alberta</li>
                        <li>Graduated from UBC Okanagan in 2023 with a Computer Science degree</li>
                        <li>Well versed in many different programming languages</li>
                        <li>Self-taught in Blender, Illustrator, and other artist applications</li>
                    </ul>
                </MediaQuery>
                <MediaQuery minWidth={"993px"}>
                    <div id="about-square" className="center ease-1s" style = {{transform : "rotate(0deg)"}}>
                        <p className="fact h2 fw-lighter fw-bold">Developer and graphic designer born in Calgary, Alberta</p>
                        <p className="fact h2 fw-lighter">Graduated from UBC Okanagan in 2023 with a Computer Science degree</p>
                        <p className="fact h2 fw-lighter">Well versed in many different programming languages</p>
                        <p className="fact h2 fw-lighter">Self-taught in Blender, Illustrator, and other artist applications</p>
                    </div>
                    <p>
                        <svg id="rotator" onClick={rotateSquare} xmlns="http://www.w3.org/2000/svg" width="32" height="32" className="bi bi-arrow-clockwise marginbtm" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
                            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
                        </svg>
                    </p>
                </MediaQuery>
                <a href="#experience" className="text-decoration-none">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" className="bi bi-chevron-double-down mb-4" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M1.646 6.646a.5.5 0 0 1 .708 0L8 12.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                        <path fillRule="evenodd" d="M1.646 2.646a.5.5 0 0 1 .708 0L8 8.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                    </svg><br/>
                    <span className="h6 text-dark">Experience</span>
                </a>
            </div>
            <div className="col-1"></div>
        </div>
    </section>
    );
}

export default About;