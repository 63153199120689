import "../css/main.css";
import Navbar from "../components/Navbar";

function Projects() {
    return (
        <div>
            {/* Nav Bar */}
            <Navbar></Navbar>
        </div>
    );
}

export default Projects;