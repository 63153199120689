function Card({img, alt, title, subtitle, text}) {
    return (
    <div className="card bg-blur m-auto" style={{width : "18rem", height : "23rem"}}>
        <img className="card-img-top p-4" src={img} alt={alt}></img>
        <div className="card-body bg-fade rounded">
            <p className="h5 card-title">{title}</p>
            <p className="h6 card-subtitle mb-2 text-muted">{subtitle}</p>
            <p className="card-text">{text}</p>
        </div>
    </div>
    );
};

export default Card;