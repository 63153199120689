/* eslint-disable react/jsx-no-comment-textnodes */
import "../css/title.css";

function Title() {
    return (
    <section className="container-fluid">
        <div className="row fill-height align-items-center">
            <div className="col-1"></div>
            <div className="col-10 text-center">
                <h1 id="name" className="lora marginbtm">Paul Gray</h1>
                <div>
                    <h2 className="marginbtm"><span className="cursive">artist</span> // <span className="code">developer</span><span className="blink fw-bold">|</span></h2>
                </div>
                <a href="#about" className="text-decoration-none">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" className="bi bi-chevron-double-down mb-4" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M1.646 6.646a.5.5 0 0 1 .708 0L8 12.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                        <path fillRule="evenodd" d="M1.646 2.646a.5.5 0 0 1 .708 0L8 8.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                    </svg><br/>
                    <span className="h6 text-dark">About</span>
                </a>
            </div>
            <div className="col-1"></div>
        </div>
    </section>
    );
}

export default Title;