import "../css/projects.css";

function Projects() {
    return (
    <section id="projects" className="container-fluid">
        <div className="row fill-height align-items-center">
            <div className="col-1"></div>
            <div className="col-10 text-center reveal">
                <h1 className="lora display-2 marginbtm">Projects</h1>
                <div className="row align-items-center marginbtm">
                    <div className="col-md-6 titlecard">
                        <img id="board" src="/images/board.png" alt={"Sands of Time"}></img>
                        <p id="game" className="h3 lora">Sands of Time</p>
                    </div>
                    <div className="col-md-6 p-5">
                        <p className="fs-5">A fast-paced blend of card game and board game, <strong>Sands of Time</strong> requires players to predict their opponents moves and position themselves in such a way to achieve victory.</p>
                        <p className="fst-italic">In development for iOS, iPadOS, and visionOS.</p>
                    </div>
                </div>
                {/* <a href="#gallery" className="text-decoration-none">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" className="bi bi-chevron-double-down mb-4" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M1.646 6.646a.5.5 0 0 1 .708 0L8 12.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                        <path fillRule="evenodd" d="M1.646 2.646a.5.5 0 0 1 .708 0L8 8.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                    </svg><br/>
                    <span className="h6 text-dark">Gallery</span>
                </a> */}
            </div>
            <div className="col-1"></div>
        </div>
    </section>
    );
}

export default Projects;